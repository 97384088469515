import React, { useState, useContext } from "react";
import { IntlContext } from "../../../../App";
import ProjectsData from '../../../../helpers/locales/en.json';
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useMedia } from "react-use";
import logo from "../../../../assets/images/logo.svg";
import mobileCubeLogo from "../../../../assets/images/mobile_cube_logo.svg";
import webDev from "../../../../assets/images/web_dev.svg";
import appDev from "../../../../assets/images/app_dev.svg";
import cloudServiceLogo from "../../../../assets/images/cloud_service_logo.svg";
import aiMlProduct from "../../../../assets/images/ai_ml_product.svg";
import webDevService from "../../../../assets/images/web_dev_service.svg";
import cloudService from "../../../../assets/images/cloud_service.svg";
import aiMiService from "../../../../assets/images/ai_ml_service.svg";
import lisaDp from "../../../../assets/images/lisa_dp.svg";
import adithDp from "../../../../assets/images/adith_dp.svg";
import michaelhDp from "../../../../assets/images/michael_dp.svg";
import sarahhDp from "../../../../assets/images/sarah_dp.svg";
import AnimationBox from "../../../component/UI/animationBox";
import Footer from "../../UI/footer";
import CustomCard from "../../UI/card";
import "./home.scss";

const HomePage = () => {
  const { messages } = useContext(IntlContext);
  const astrik_lang = messages.en;
  const peopleThinkData = ProjectsData?.peopleThinkData;
  const whatToDoList = ProjectsData?.whatToDoList;
  const ourProcess = ProjectsData?.ourProcess;

  const [isHovered, setIsHovered] = useState(true);
  const [hoveredId, setHoveredId] = useState(1);

  const isMediumViewport = useMedia(
    "(min-width: 490px) and (max-width: 1920px)"
  );

  const getImage = (imageName) => {
    switch (imageName) {
      case "sarahhDp":
        return sarahhDp;
      case "adithDp":
        return adithDp;
      case "michaelhDp":
        return michaelhDp;
      case "lisaDp":
        return lisaDp;
      case "webDev":
        return webDev;
      case "appDev":
        return appDev;
      case "cloudServiceLogo":
        return cloudServiceLogo;
      case "aiMlProduct":
        return aiMlProduct;
      case "webDevService":
        return webDevService;
      case "cloudService":
        return cloudService;
      case "aiMiService":
        return aiMiService;

      default:
        return null;
    }
  }

  const AnimatedOurProcessBox = ({ children, index }) => {
    const maxIndex = ourProcess.length - 1;
    const reversedIndex = maxIndex - index;
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: "-50px 0px",
    });

    const isAnimationEnabled = useMedia("(min-width: 441px)");

    const props = useSpring({
      opacity: isAnimationEnabled && inView ? 1 : 0,
      transform:
        isAnimationEnabled && inView
          ? "translateY(0) scale(1)"
          : "translateY(0) scale(0.8)",
      config: { duration: 700 },
      delay: isAnimationEnabled ? reversedIndex * 100 : 0,
    });

    return (
      <animated.div ref={ref} style={isAnimationEnabled ? props : {}}>
        {children}
      </animated.div>
    );
  };

  const AnimatedWhatToDoBox = ({ children, index }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: "-50px 0px",
    });

    const isAnimationEnabled = useMedia("(min-width: 441px)");

    const props = useSpring({
      opacity: isAnimationEnabled && inView ? 1 : 0,
      transform:
        isAnimationEnabled && inView ? "translateX(0)" : "translateX(-50px)",
      from: { opacity: 0, transform: "translateX(-50px)" },
      config: { duration: 700 },
      delay: isAnimationEnabled ? index * 100 : 0,
    });

    return (
      <animated.div ref={ref} style={isAnimationEnabled ? props : {}}>
        {children}
      </animated.div>
    );
  };

  const handleToggleView = (id) => {
    setHoveredId(id);
    setIsHovered(true);
  };

  return (
    <>
      <Box className="main-wrapper-home">
        <Box className="home-img-container">
          <Box className="logo-content">
            <Typography
              variant="h6"
              className="logo-content-text text-pointer "
            >
              {astrik_lang?.auth?.welcome_to}
            </Typography>
            <Typography
              variant="h2"
              className="logo-content-text-astrik text-pointer "
            >
              {astrik_lang?.auth?.astrik}
            </Typography>
          </Box>

          <Box className="logo-main-conainer">
            <Typography
              variant="h6"
              className="logo-content-subtext text-pointer "
            >
              {astrik_lang?.auth?.we_define}
            </Typography>
            <Box className="Ellipse-shadow-container t-pointer ">
              <img className="home-img" src={logo} alt="" />
            </Box>
            <Typography
              variant="h6"
              className="logo-content-subtext text-pointer "
            >
              {astrik_lang?.auth?.the_future}
            </Typography>
          </Box>
          <Box className="responsive-logo-main-conainer">
            <Box className="responsive-ellipse-shadow-container t-pointer">
              <img className="home-img" src={mobileCubeLogo} alt="" />
            </Box>

            <Typography
              variant="h6"
              className="logo-content-subtext text-pointer "
            >
              {astrik_lang?.auth?.we_define_the_future}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className="info-main-container">
            <Box className="info-container">
              <p className="text-font-public ">
                {astrik_lang?.auth?.we_build_software}
              </p>
            </Box>
            <Box className="content-center ">
              <p className="text-font-sans t-center">
                {astrik_lang?.auth?.got_an_idea}
              </p>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  className="create-something t-pointer"
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                >
                  {astrik_lang?.auth?.lets_create_something_cool}
                </Box>
                <div className="test-border"></div>
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          className="our-process-container "
          style={{ position: "relative" }}
        >
          <div className="test-1-border"></div>
          <Typography className="heading-our-text t-center">
            {astrik_lang?.auth?.our_process_title}
          </Typography>
          <Box className="process-container">
            <Typography
              className="text-font"
              dangerouslySetInnerHTML={{
                __html: astrik_lang?.auth?.our_process_content,
              }}
            />
          </Box>

          <Box className="our-process-box-container">
            {/* <div className="test-2-border"></div> */}
            {ourProcess?.map((item, index) => {
              return (
                <>
                  <AnimatedOurProcessBox index={index} key={item.id}>
                    <Box className="process-box t-pointer">
                      <p className="box-text">{item?.id}</p>
                      <p className="box-para box-text">{item?.title}</p>
                    </Box>
                  </AnimatedOurProcessBox>
                </>
              );
            })}
          </Box>
          <div className="test-border111"></div>
        </Box>
        <Box className="what-do-container t-pointer">
          <div className="line"></div>
          <div className="line-sub1"></div>
          <div className="line-sub2"></div>
          <div className="line3"></div>
          <Box className="what-do-title-container">
            <Typography className="what-do-text text-pointer">
              {astrik_lang?.auth?.what_do_we_do}
            </Typography>
            {/* <Typography
              className="learn-text text-pointer"
              dangerouslySetInnerHTML={{
                __html: astrik_lang?.auth?.learn_more,
              }}
            /> */}
          </Box>
          <p
            className="text-font text-pointer"
            style={{ paddingBottom: "60px" }}
          >
            {astrik_lang?.auth?.what_do_we_do_content}
          </p>
        </Box>

        <Box className="what-do-boxs">
          <div className="line2"></div>
          <div className="line6"></div>
          <div className="line7"></div>
          <Box
            className="faq-grid t-pointer"
            style={{ pointerEvents: isMediumViewport ? "none" : "auto" }}
          >
            {whatToDoList
              ?.filter(
                (item) =>
                  !(
                    (item.id === 6 || item.id === 7) &&
                    window.innerWidth >= 1800 &&
                    window.innerWidth <= 2560
                  )
              )
              ?.map((item, index) => (
                <Box gridColumn={item?.gridColumn} key={item?.id}>
                  {item?.title && (
                    <AnimatedWhatToDoBox index={index}>
                      <AnimationBox>
                        <Box
                          className={`faq-content-box`}
                          style={{
                            height:
                              isHovered && item?.id === hoveredId ? "auto" : "",
                          }}
                          onClick={() => handleToggleView(item?.id)}
                        >
                          <Box className="box-logo-container">
                            <img
                              className={`box-logo`}
                              style={{
                                display:
                                  isHovered && item?.id === hoveredId
                                    ? "block"
                                    : "",
                              }}
                              // src={item?.image}
                              src={getImage(item?.image)}
                              alt=""
                            />
                          </Box>
                          <p className="box-text">{item?.subText}</p>
                        </Box>
                        <Box
                          className="web-title-box"
                          onClick={() => handleToggleView(item?.id)}
                        >
                          <p className="box-title">{item?.title}</p>
                          <span>
                            <img
                              className="service-logo"
                              // src={item?.icon}
                              src={getImage(item?.icon)}
                              alt=""
                            />
                          </span>
                        </Box>
                      </AnimationBox>
                    </AnimatedWhatToDoBox>
                  )}
                </Box>
              ))}
          </Box>

          <Box className="our-work-container " style={{ position: "relative" }}>
            <div className="test-3-border"></div>
            <Typography className="heading-work-text text-pointer t-center">
              Our Work
            </Typography>
            <p className="text-font t-center">
              A few handpicked projects for your pleasure
            </p>
            <Box>
              <CustomCard className="t-pointer" />
            </Box>
            <Box
              className="lets-work-together t-pointer"
              style={{ position: "relative" }}
              onClick={() => {
                window.location.href = "/contact";
              }}
            >
              <div className="test-4-border"></div>
              Let’s work Together
            </Box>
            <div className="line4"></div>
            <div className="line5"></div>
            <Box
              className="people-think-main-container"
              style={{ position: "relative" }}
            >
              <div className="test-5-border"></div>
              <Box className="people-think-container content-center t-pointer">
                <Typography className="people-think-title text-pointer">
                  What people think about us?
                </Typography>
                <Grid container spacing={2}>
                  {peopleThinkData?.map((item) => (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box className="people-think-main-sec">
                        <Box className="people-user-profile-sec">
                          <Box className="people-profile-img-sec">
                            <Box className="people-profile-img">
                              <Avatar src={getImage(item.dp)} alt="" />
                            </Box>
                            <Box className="people-profile-name">
                              <Typography className="profile-name-text text-pointer">
                                {item?.name}
                              </Typography>
                              <Typography className="profile-role-text text-pointer">
                                {item?.role}
                              </Typography>
                              <Box className="people-profile-info">
                                <Typography className="profile-mail-text text-pointer">
                                  {item?.username}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          {/* <Box className="people-profile-info">
                            <Typography className="profile-mail-text text-pointer">
                              {item?.username}
                            </Typography>
                          </Box> */}
                        </Box>
                        <Box className="people-profile-dec">
                          <Typography className="profile-dec-text text-pointer">
                            {item?.dec}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default HomePage;
