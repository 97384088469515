import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@mui/material";
import "./ui.scss";

const DialogBox = ({ handleClose, open, title }) => {
  return (
    <Dialog
      className="custom-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        <DialogTitle>
          <Box className="custom-dialog-title">
            <Typography variant="h3" className="dialog-title">
              {title}
            </Typography>
          </Box>
        </DialogTitle>
      </Box>
      <Box className="pause-sync-btn">
        <Button
          className="blue-botton btn-sec"
          onClick={() => {
            handleClose();
          }}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogBox;
