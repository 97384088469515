import { Box, Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Footer from "../../UI/footer";
import logo from "../../../../assets/images/contact_logo.svg";
import light from "../../../../assets/images/light-img.svg";
import "./contact.scss";
import DialogBox from "../../UI/dialogBox";
import emailjs from '@emailjs/browser';

const ContactUsPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [discussionSubject, setDiscussionSubject] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    discussionSubject: "",
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleEnterPress = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required!";
    }
    if (!email) {
      errors.email = "Email is required!";
    }
    if (!phoneNumber) {
      errors.phoneNumber = "Phone Number is required!";
    } else if (!/^\d+$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone Number must contain only digits!";
    }
    // else if (phoneNumber.length !== 10) {
    //   errors.phoneNumber = "Phone Number must be 10 digits long!";
    // }
    if (!discussionSubject) {
      errors.discussionSubject = "Subject of Discussion is required!";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    // let message = "\nNew message from " + email + "\n"
    // if(name && name != ''){
    //   message += '\n Name : '+ name
    // }
    // if(email && email != ''){
    //   message += '\n Email : '+ email
    // }
    // if(phoneNumber && phoneNumber != ''){
    //   message += '\n Ph. Number : '+ phoneNumber
    // } 
    // if(discussionSubject && discussionSubject != ''){
    //   message += '\n Subject : '+ discussionSubject
    // } 


    // var settings = {
    //   "crossDomain": true,
    //   "url": "https://api.twilio.com/2010-04-01/Accounts/AC48e9bec02c2df9d512f6c3b485402b57/Messages.json",
    //   "method": "POST",
    //   "headers": {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Authorization": "Basic "+ btoa('AC48e9bec02c2df9d512f6c3b485402b57' + ':' + "ae93d86fb3aaa455fb7c4c6e59b04b4e"),
    //   },
    //   "body": new URLSearchParams({
    //     From: "+16592465721",
    //     To: "+917567993550",
    //     Body: message
    //   })
    // }

    // console.log("setting", settings);
    // fetch(settings.url, {
    //   method: settings.method,
    //   headers: settings.headers,
    //   body: settings.body
    // })
    // .then(response => {
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   return response.json();
    // })
    // .then(data => {
    //   // console.log(data);
    //   setOpenDialog(true);
    // })
    // .catch(error => {
    //   console.error('There was an error!', error);
    // });

    let templateData = {
      from_name: name,
      from_no: phoneNumber,
      from_email: email,
      message: discussionSubject
    }

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateData, {
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    }).then(
      function (response) {
        console.log('SUCCESS!', response.status, response.text);
        setOpenDialog(true);
      },
      function (err) {
        console.log('FAILED...', err);
      },
    );

    setFieldErrors({});
    setName("");
    setEmail("");
    setPhoneNumber("");
    setDiscussionSubject("");
  };

  return (
    <>
      <Box className="main-wrapper-contact">
        <Box className="logo-main-container t-pointer">
          <Box className="ellipse-shadow-contact-container">
            <img className="contact-img" src={logo} alt="" />
          </Box>
          <Typography variant="h6" className="logo-content-title text-pointer">
            Got an Idea?
            <br /> <span>Let's Make it Happen!</span>
          </Typography>
          <Typography className="logo-content-para text-pointer">
            Our team of experts is here to turn your vision into reality. Let's
            work together to bring your idea to life.
          </Typography>
        </Box>
        <Box className="make-it-happen-section-block t-pointer">
          <Box className="make-it-happen-container">
            <Box className="make-it-happen-content-wreppar">
              <img src={light} alt="" className="img-block" />
              <Box className="content-block">
                <Typography className="make-it-happen-title text-pointer">
                  Let's make it happen!
                </Typography>
                <Typography className="make-it-happen-para text-pointer">
                  "Connect with our team to discuss your project or idea. Simply
                  fill out this form, and we'll be in touch soon to explore how
                  we can bring your idea to life!"
                </Typography>
                <Divider
                  className="mb-35"
                  style={{ backgroundColor: "#F0F0DC" }}
                />
                <Box className="make-it-happen-info">
                  <Box className="make-it-happen-details">
                    <Typography className="field-title-text text-pointer">
                      Name
                    </Typography>
                    <TextField
                      className="field-label-text mb-35"
                      placeholder="Enter your Name"
                      value={name}
                      // onChange={(e) => setName(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEnterPress();
                      }}
                      onChange={(e) => {
                        setName(e.target.value);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          name: "",
                        }));
                      }}
                    />
                    {fieldErrors.name && (
                      <Typography className="error-message mb-35">
                        {fieldErrors.name}
                      </Typography>
                    )}

                    <Typography className="field-title-text text-pointer">
                      Email ID
                    </Typography>
                    <TextField
                      className="field-label-text mb-35"
                      placeholder="Enter your Email ID"
                      value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "",
                        }));
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEnterPress();
                      }}
                    />
                    {fieldErrors.email && (
                      <Typography className="error-message mb-35">
                        {fieldErrors.email}
                      </Typography>
                    )}
                    <Typography className="field-title-text text-pointer">
                      Phone Number
                    </Typography>
                    <TextField
                      className="field-label-text"
                      placeholder="Enter your Phone Number"
                      value={phoneNumber}
                      // onChange={(e) => setPhoneNumber(e.target.value)}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        if (/^\d+$/.test(inputValue) || inputValue === "") {
                          setPhoneNumber(inputValue);
                          setFieldErrors((prevErrors) => ({
                            ...prevErrors,
                            phoneNumber: "",
                          }));
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEnterPress();
                      }}
                    />
                    {fieldErrors.phoneNumber && (
                      <Typography className="error-message mt-35">
                        {fieldErrors.phoneNumber}
                      </Typography>
                    )}
                  </Box>
                  <Box className="make-it-subject-dicussion">
                    <Typography className="subject-discussion-text text-pointer">
                      Subject of Discussion
                    </Typography>
                    <TextField
                      multiline
                      className="field-label-text"
                      // inputProps={{
                      //   style: {
                      //     height: "314px",
                      //   },
                      // }}
                      // maxRows={5}
                      rows={13}
                      placeholder="Tell us about your idea in a nutshell - just a few sentences to get us excited! What are you hoping to achieve, and how can we help make it happen?"
                      value={discussionSubject}
                      // onChange={(e) => setDiscussionSubject(e.target.value)}
                      onChange={(e) => {
                        setDiscussionSubject(e.target.value);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          discussionSubject: "",
                        }));
                      }}
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter") handleEnterPress();
                    // }}
                    />
                    {fieldErrors.discussionSubject && (
                      <Typography className="error-message mt-35">
                        {fieldErrors.discussionSubject}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className="make-it-happen-box">
                  <Box className="make-it-happen" onClick={handleEnterPress}>
                    Make it Happen!
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <DialogBox
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        title="Thank you for your submission! Our team will get back to you soon"
      />
    </>
  );
};

export default ContactUsPage;
