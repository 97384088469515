import React, { useEffect, useRef, useContext, useState } from "react";
import { IntlContext } from "../../../../App";
import { Box, Divider, Typography } from "@mui/material";
// import Vector from "../../../../assets/images/arrow.svg";
import CloudNationCover from "../../../../assets/images/cloudnation_covers_border.png";
// import ChotaDhobiCover from "../../../../assets/images/chotadhobicovers_border.png";
import InstaticketProject from "../../../../assets/images/instaticket_projects_border.png";
import Footer from "../../UI/footer";
import CustomPagination from "../../UI/pagination";
import "./projects.scss";

const Projects = [
  {
    heading: "Cloudnation",
    title: "WEB APP ",
    info: "Cloudnation is a Platform-as-a-Service (PaaS) cloud-based service that automates deployment and infrastructure management. Our focus is on simplicity, ensuring a stress-free experience in handling cloud infrastructure, allowing businesses to concentrate on growth rather than infrastructure management.",
    image: CloudNationCover,
  },
  {
    heading: "InstaTickets",
    title: "WEB APP ",
    info: "Say goodbye to long queues and hello to hassle-free ticket booking for movies, operas, concerts, and more. With a delightful and user-friendly interface, InstaTickets brings joy and excitement to your fingertips. Get ready to dive into a world of entertainment with just a few taps. From blockbuster movies to show-stopping performances, InstaTickets is your go-to companion for all things fun and fabulous!",
    image: InstaticketProject,
  },
  {
    heading: "CRM",
    title: "WEB APP & MOBILE APP",
    info: "Fungataua's CRM (Customer Relationship Management) project aims to enhance the company's customer interactions and streamline business processes. The CRM system will centralize customer data, enabling Fungataua to manage relationships more effectively, analyze customer preferences, and tailor their services accordingly. With features such as contact management, sales automation, and customer support tools, Fungataua's CRM project seeks to improve overall customer satisfaction, increase sales efficiency, and foster long-term client loyalty. The implementation of this CRM solution is poised to strengthen Fungataua's ability to understand and meet customer needs while optimizing internal workflows for better business outcomes.",
    image: require("../../../../assets/images/CRM.png"),
  },
  // {
  //   heading: "Vosupport.ai",
  //   title: "WEB APP ",
  //   info: "We're proud to have developed a game-changing AI-based Support Calling system for our client. Our project seamlessly integrates with their support module, emulating the capabilities of a live support representative. The impact has been remarkable – a 70-80% reduction in call volumes to customer care, allowing our client to optimize their resources and respond to customer queries more efficiently. Our solution has not only saved them valuable time but has also elevated their customer support to new levels. It's gratifying to see our technology in action, making a real difference for our client and enhancing their customer service experience.",
  //   image: require("../../../../assets/images/project3.svg").default,
  // },
  {
    heading: "ERP",
    title: "WEB APP",
    info: "The ERP (Enterprise Resource Planning) project for the Qatar-based company aims to integrate and streamline various business processes across departments, providing a comprehensive solution to enhance overall efficiency. This ERP system will encompass modules for finance, human resources, supply chain, and customer relationship management, allowing the company to manage resources, track transactions, and improve decision-making through real-time data analysis. With a focus on scalability and adaptability to Qatar's specific business environment, the ERP implementation will enable the company to achieve better visibility into its operations, reduce manual work, and optimize workflows for increased productivity. The ultimate goal is to facilitate informed decision-making, enhance collaboration among different departments, and contribute to the company's growth and competitiveness in the Qatari market.",
    image: require("../../../../assets/images/ERP-1.png"),
  },
  {
    heading: "Roof Visualizer",
    title: "WEB APP ",
    info: "The roof visualizer project, designed for a New Zealand-based roofing company, leverages AI technology to revolutionize the customer experience in choosing roofing solutions. Using advanced image recognition algorithms, the system can accurately detect existing roofs in photos submitted by users and seamlessly replace them with customized roof options. This innovative tool not only allows customers to visualize different roofing styles on their own homes but also streamlines the decision-making process by providing a realistic preview of how the chosen roof will look. The AI-driven roof visualizer enhances customer engagement, expedites the selection process, and empowers clients to make informed decisions tailored to their preferences, thereby contributing to increased customer satisfaction and potentially boosting sales for the roofing company.",
    image: require("../../../../assets/images/RoofVisualizer.png"),
  },
  // {
  //   heading: "ChotaDhobi",
  //   title: " MOBILE APP",
  //   info: "The ChotaDhobi project introduces a seamless and efficient solution to laundry services through a user-friendly mobile application. Customers can easily submit their laundry requests via the app, track the status of their clothes in real-time, and receive notifications upon completion. The system is equipped with an intuitive interface, allowing users to customize their preferences and streamline the laundry process. On the administrative side, the laundry shop gains control through a dedicated app, facilitating order management, inventory tracking, and communication with customers. This automation not only enhances customer convenience by offering a digital platform for laundry services but also optimizes operational workflows for the laundry shop. The ChotaDhobi app ultimately provides a modern, efficient, and user-centric approach to laundry management, benefitting both customers and the laundry service providers.",
  //   image: ChotaDhobiCover,
  // },
  {
    heading: "DropTaxi",
    title: "WEB APP & MOBILE APP",
    info: "DropTaxi's website introduces a user-friendly platform for convenient and efficient cab booking services. Users can easily access the website to book taxis, specifying their location, destination, and preferred time. The platform provides real-time availability of cabs, allowing customers to choose from a fleet of vehicles based on their preferences and requirements. The website is designed with a secure and straightforward payment system, ensuring a seamless booking experience. With features such as live tracking, booking history, and fare estimates, DropTaxi's website enhances customer satisfaction by providing transparency and reliability in the taxi booking process. The user-centric design aims to simplify the entire experience, making it accessible for a diverse range of customers seeking reliable transportation services.",
    image: require("../../../../assets/images/DropTaxi.png"),
  },
  // {
  //   heading: "Certificate Verification",
  //   title: "WEB APP ",
  //   info: "Introducing our cutting-edge Certificate Verification and Distribution SDK, a seamlessly integrable solution for any online portal. Our flexible SDK ensures hassle-free implementation, allowing organizations to effortlessly verify and distribute certificates to participants. With user-friendly features and robust security measures, our system guarantees a streamlined and efficient process. Customizable to suit unique needs, the SDK supports various certificate formats and ensures a user-centric experience. Empower your platform with our solution to authenticate credentials swiftly, providing participants with credible certifications. Elevate the trustworthiness of your services and enhance user satisfaction with our innovative Certificate Verification and Distribution SDK – the epitome of simplicity and reliability for your organization.",
  //   image: require("../../../../assets/images/project4.svg").default,
  // },
  {
    heading: "Stock Tracker",
    title: "WEB APP ",
    info: "a comprehensive solution tailored for our esteemed client in the USA. This user-friendly platform enables seamless tracking and advanced analysis of stock data, offering a plethora of features for investors. With real-time updates and intuitive interfaces, users can monitor stocks, conduct in-depth analyses, and access a range of stock-related features. Our application empowers investors with timely insights, customizable alerts, and a user-centric design for a superior trading experience. From robust data tracking to personalized portfolio management, this application is designed to meet the diverse needs of stock enthusiasts. Elevate your investment journey with our Stock Tracker – your gateway to efficient and informed stock market decisions.",
    image: require("../../../../assets/images/Stocktracker.png"),
  },
  {
    heading: "Cryptsend",
    title: "WEB APP ",
    info: "Introducing CryptSend, our innovative application for secure file transfers within and beyond your organization. With a state-of-the-art encryption algorithm and no database dependencies, CryptSend ensures end-to-end security for seamless file sharing. Enjoy peace of mind as sensitive data travels securely both internally and externally, safeguarded by robust encryption measures. This user-friendly solution prioritizes privacy, providing a secure channel for confidential information without compromising on efficiency. CryptSend's intuitive interface and streamlined process make it an indispensable tool for organizations prioritizing data integrity. Experience the next level of secure file sharing with CryptSend – where cutting-edge encryption meets effortless usability.",
    image: require("../../../../assets/images/Cryptsend.png"),
  },
  {
    heading: "Partuma Booking",
    title: "WEB APP & MOBILE APP",
    info: "Introducing Partuma, your go-to hotel booking website for a seamless and delightful travel experience. With a user-friendly interface, Partuma allows users to effortlessly browse, compare, and book hotels worldwide. The platform offers a diverse range of accommodations to suit various preferences and budgets. Its intuitive design ensures easy navigation, enabling users to find the perfect stay with just a few clicks. Partuma also features secure payment options, real-time availability updates, and personalized recommendations, enhancing the overall booking process. Whether for business or leisure, Partuma simplifies the hotel booking journey, ensuring travelers can focus on creating memorable experiences. Elevate your travel plans with Partuma – your gateway to hassle-free hotel reservations.",
    image: require("../../../../assets/images/Partumabooking.png"),
  },
];

const ProjectsPage = () => {
  const { messages } = useContext(IntlContext);
  const astrik_lang = messages.en;
  const mainWrapperRef = useRef();
  const projectsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    mainWrapperRef.current.scrollIntoView({
      behavior: "smooth",
    });

    return () => { };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    mainWrapperRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const startIndex = (currentPage - 1) * projectsPerPage;
  const endIndex = startIndex + projectsPerPage;
  const displayedProjects = Projects.slice(startIndex, endIndex);

  return (
    <>
      <Box className="main-wrapper-projects" ref={mainWrapperRef}>
        <Box className="all-projects-container t-pointer">
          <Typography className="heading-projects-text text-pointer">
            {astrik_lang?.auth?.all_projects}
          </Typography>
          <p className="text-font text-pointer">
            {astrik_lang?.auth?.all_projects_content}
          </p>
          <Divider style={{ backgroundColor: "white" }} />
        </Box>
        {displayedProjects.map((project, index) => (
          <Box key={index} className="projects-info-container" display="flex">
            <Box className="projects-detail-sec" flex="1">
              <Box className="project-content-sec t-pointer" width="100%">
                <p className="heading-text">{project?.heading}</p>
                <p className="title-text">{project?.title}</p>
                <p className="info-text">{project?.info}</p>
              </Box>
            </Box>
            <Box className="projects-detail-sec" flex="1">
              <Box>
                {project.image instanceof Promise ? (
                  <img
                    className="projects-img t-pointer"
                    src={project?.image.then((module) => module.default)}
                    alt=""
                  // width="100%"
                  />
                ) : (
                  <img
                    className="projects-img t-pointer"
                    src={project?.image}
                    alt=""
                  // width="100%"
                  />
                )}
              </Box>
            </Box>
          </Box>
        ))}

        <CustomPagination
          totalItems={Projects?.length}
          itemsPerPage={projectsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />

        <Box className="lets-work-together-box">
          <Box
            className="lets-work-togethe t-pointer"
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            {astrik_lang?.auth?.lets_work_together}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ProjectsPage;

// old code : //

// import React, { useEffect, useRef, useContext } from "react";
// import { IntlContext } from "../../../../App";
// import { Box, Divider, Typography } from "@mui/material";
// // import Project1 from "../../../../assets/images/project1.svg";
// // import Project2 from "../../../../assets/images/project2.svg";
// // import Project3 from "../../../../assets/images/project3.svg";
// // import Project4 from "../../../../assets/images/project4.svg";
// import Vector from "../../../../assets/images/arrow.svg";
// import "./projects.scss";
// import Footer from "../../UI/footer";
// import CustomPagination from "../../UI/pagination";

// const Projects = [
//   {
//     heading: "Cloudnation",
//     title: "WEB APP & MOBILE APP",
//     info: "Cloudnation is a Platform-as-a-Service (PaaS) cloud-based service that automates deployment and infrastructure management. Our focus is on simplicity, ensuring a stress-free experience in handling cloud infrastructure, allowing businesses to concentrate on growth rather than infrastructure management.",
//     image: require("../../../../assets/images/project1.svg").default,
//   },
//   {
//     heading: "CRM",
//     title: "WEB APP & MOBILE APP",
//     info: "Fungataua's CRM (Customer Relationship Management) project aims to enhance the company's customer interactions and streamline business processes. The CRM system will centralize customer data, enabling Fungataua to manage relationships more effectively, analyze customer preferences, and tailor their services accordingly. With features such as contact management, sales automation, and customer support tools, Fungataua's CRM project seeks to improve overall customer satisfaction, increase sales efficiency, and foster long-term client loyalty. The implementation of this CRM solution is poised to strengthen Fungataua's ability to understand and meet customer needs while optimizing internal workflows for better business outcomes.",
//     image: require("../../../../assets/images/project2.svg").default,
//   },
//   {
//     heading: "Vosupport.ai",
//     title: "WEB APP & MOBILE APP",
//     info: "We're proud to have developed a game-changing AI-based Support Calling system for our client. Our project seamlessly integrates with their support module, emulating the capabilities of a live support representative. The impact has been remarkable – a 70-80% reduction in call volumes to customer care, allowing our client to optimize their resources and respond to customer queries more efficiently. Our solution has not only saved them valuable time but has also elevated their customer support to new levels. It's gratifying to see our technology in action, making a real difference for our client and enhancing their customer service experience.",
//     image: require("../../../../assets/images/project3.svg").default,
//   },
//   {
//     heading: "ERP",
//     title: "WEB APP & MOBILE APP",
//     info: "The ERP (Enterprise Resource Planning) project for the Qatar-based company aims to integrate and streamline various business processes across departments, providing a comprehensive solution to enhance overall efficiency. This ERP system will encompass modules for finance, human resources, supply chain, and customer relationship management, allowing the company to manage resources, track transactions, and improve decision-making through real-time data analysis. With a focus on scalability and adaptability to Qatar's specific business environment, the ERP implementation will enable the company to achieve better visibility into its operations, reduce manual work, and optimize workflows for increased productivity. The ultimate goal is to facilitate informed decision-making, enhance collaboration among different departments, and contribute to the company's growth and competitiveness in the Qatari market.",
//     image: require("../../../../assets/images/project4.svg").default,
//   },
//   {
//     heading: "Roof Visualizer",
//     title: "WEB APP & MOBILE APP",
//     info: "The roof visualizer project, designed for a New Zealand-based roofing company, leverages AI technology to revolutionize the customer experience in choosing roofing solutions. Using advanced image recognition algorithms, the system can accurately detect existing roofs in photos submitted by users and seamlessly replace them with customized roof options. This innovative tool not only allows customers to visualize different roofing styles on their own homes but also streamlines the decision-making process by providing a realistic preview of how the chosen roof will look. The AI-driven roof visualizer enhances customer engagement, expedites the selection process, and empowers clients to make informed decisions tailored to their preferences, thereby contributing to increased customer satisfaction and potentially boosting sales for the roofing company.",
//     image: require("../../../../assets/images/project1.svg").default,
//   },
//   {
//     heading: "ChotaDhobi",
//     title: "WEB APP & MOBILE APP",
//     info: "The ChotaDhobi project introduces a seamless and efficient solution to laundry services through a user-friendly mobile application. Customers can easily submit their laundry requests via the app, track the status of their clothes in real-time, and receive notifications upon completion. The system is equipped with an intuitive interface, allowing users to customize their preferences and streamline the laundry process. On the administrative side, the laundry shop gains control through a dedicated app, facilitating order management, inventory tracking, and communication with customers. This automation not only enhances customer convenience by offering a digital platform for laundry services but also optimizes operational workflows for the laundry shop. The ChotaDhobi app ultimately provides a modern, efficient, and user-centric approach to laundry management, benefitting both customers and the laundry service providers.",
//     image: require("../../../../assets/images/project2.svg").default,
//   },
//   {
//     heading: "DropTaxi",
//     title: "WEB APP & MOBILE APP",
//     info: "DropTaxi's website introduces a user-friendly platform for convenient and efficient cab booking services. Users can easily access the website to book taxis, specifying their location, destination, and preferred time. The platform provides real-time availability of cabs, allowing customers to choose from a fleet of vehicles based on their preferences and requirements. The website is designed with a secure and straightforward payment system, ensuring a seamless booking experience. With features such as live tracking, booking history, and fare estimates, DropTaxi's website enhances customer satisfaction by providing transparency and reliability in the taxi booking process. The user-centric design aims to simplify the entire experience, making it accessible for a diverse range of customers seeking reliable transportation services.",
//     image: require("../../../../assets/images/project3.svg").default,
//   },
//   {
//     heading: "Certificate Verification",
//     title: "WEB APP & MOBILE APP",
//     info: "Introducing our cutting-edge Certificate Verification and Distribution SDK, a seamlessly integrable solution for any online portal. Our flexible SDK ensures hassle-free implementation, allowing organizations to effortlessly verify and distribute certificates to participants. With user-friendly features and robust security measures, our system guarantees a streamlined and efficient process. Customizable to suit unique needs, the SDK supports various certificate formats and ensures a user-centric experience. Empower your platform with our solution to authenticate credentials swiftly, providing participants with credible certifications. Elevate the trustworthiness of your services and enhance user satisfaction with our innovative Certificate Verification and Distribution SDK – the epitome of simplicity and reliability for your organization.",
//     image: require("../../../../assets/images/project4.svg").default,
//   }, 
//   {
//     heading: "Stock Tracker",
//     title: "WEB APP & MOBILE APP",
//     info: "a comprehensive solution tailored for our esteemed client in the USA. This user-friendly platform enables seamless tracking and advanced analysis of stock data, offering a plethora of features for investors. With real-time updates and intuitive interfaces, users can monitor stocks, conduct in-depth analyses, and access a range of stock-related features. Our application empowers investors with timely insights, customizable alerts, and a user-centric design for a superior trading experience. From robust data tracking to personalized portfolio management, this application is designed to meet the diverse needs of stock enthusiasts. Elevate your investment journey with our Stock Tracker – your gateway to efficient and informed stock market decisions.",
//     image: require("../../../../assets/images/project1.svg").default,
//   },
//   {
//     heading: "Cryptsend",
//     title: "WEB APP & MOBILE APP",
//     info: "Introducing CryptSend, our innovative application for secure file transfers within and beyond your organization. With a state-of-the-art encryption algorithm and no database dependencies, CryptSend ensures end-to-end security for seamless file sharing. Enjoy peace of mind as sensitive data travels securely both internally and externally, safeguarded by robust encryption measures. This user-friendly solution prioritizes privacy, providing a secure channel for confidential information without compromising on efficiency. CryptSend's intuitive interface and streamlined process make it an indispensable tool for organizations prioritizing data integrity. Experience the next level of secure file sharing with CryptSend – where cutting-edge encryption meets effortless usability.",
//     image: require("../../../../assets/images/project2.svg").default,
//   },
//   {
//     heading: "Partuma Booking",
//     title: "WEB APP & MOBILE APP",
//     info: "Introducing Partuma, your go-to hotel booking website for a seamless and delightful travel experience. With a user-friendly interface, Partuma allows users to effortlessly browse, compare, and book hotels worldwide. The platform offers a diverse range of accommodations to suit various preferences and budgets. Its intuitive design ensures easy navigation, enabling users to find the perfect stay with just a few clicks. Partuma also features secure payment options, real-time availability updates, and personalized recommendations, enhancing the overall booking process. Whether for business or leisure, Partuma simplifies the hotel booking journey, ensuring travelers can focus on creating memorable experiences. Elevate your travel plans with Partuma – your gateway to hassle-free hotel reservations.",
//     image: require("../../../../assets/images/project3.svg").default,
//   },
// ];

// const ProjectsPage = () => {
//   const { messages } = useContext(IntlContext);
//   const astrik_lang = messages.en;
//   const mainWrapperRef = useRef();

//   useEffect(() => {
//     mainWrapperRef.current.scrollIntoView({
//       behavior: "smooth",
//     });

//     return () => {};
//   }, []);

//   return (
//     <>
//       <Box className="main-wrapper-projects" ref={mainWrapperRef}>
//         <Box className="all-projects-container t-pointer">
//           <Typography className="heading-projects-text text-pointer">
//             {astrik_lang?.auth?.all_projects}
//           </Typography>
//           <p className="text-font text-pointer">
//             {astrik_lang?.auth?.all_projects_content}
//           </p>
//           <Divider style={{ backgroundColor: "white" }} />
//         </Box>

//         {Projects?.map((project, index) => (
//           <Box key={index} className="projects-info-container">
//             <Box className="projects-detail-sec">
//               <Box className="project-content-sec t-pointer">
//                 <p className="heading-text">{project?.heading}</p>
//                 <p className="title-text">{project?.title}</p>
//                 <p className="info-text">{project?.info}</p>
//                 <p className="info-text-view">
//                   View complete Presentation
//                   <span>
//                     <img
//                       className="projects-img t-pointer"
//                       style={{ marginLeft: "12.09px" }}
//                       src={Vector}
//                       alt=""
//                     />
//                   </span>
//                 </p>
//               </Box>
//               <Box>
//                 <img
//                   className="projects-img t-pointer"
//                   src={project?.image}
//                   alt=""
//                 />
//               </Box>
//             </Box>
//           </Box>
//         ))}

//         <CustomPagination />
//         <Box className="lets-work-together-box">
//           <Box className="lets-work-togethe t-pointer">
//             {astrik_lang?.auth?.lets_work_together}
//           </Box>
//         </Box>
//       </Box>
//       <Footer />
//     </>
//   );
// };

// export default ProjectsPage;
