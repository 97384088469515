import React, { Fragment, createContext, useState } from "react";
import { useRoutes } from "react-router-dom";
import { adminRoutes } from "./routes";
import messagesEn from "../src/helpers/locales/en.json";

const Context = createContext();

const App = () => {
  const [messages, setMessages] = useState({
    en: { ...messagesEn },
  });

  const routing = useRoutes(adminRoutes);

  return (
    <Fragment>
      <Context.Provider value={{ messages, setMessages }}>
        {routing}
      </Context.Provider>
      {/* <CustomCursor /> */}
    </Fragment>
  );
};

export default App;
export { Context as IntlContext };
