import React, { useEffect, useRef, useContext } from "react";
import { IntlContext } from "../../../../App";
import { Box, Typography } from "@mui/material";
import star from "../../../../assets/images/star_astrik.svg";
// import about from "../../../../assets/images/about_img.svg";
import inclusive from "../../../../assets/images/inclusive.svg";
import bold from "../../../../assets/images/bold.svg";
import agile from "../../../../assets/images/agile.svg";
import purposeDriven from "../../../../assets/images/purpose_driven.svg";
import customerObsessed from "../../../../assets/images/customer_obsessed.svg";
import about from "../../../../assets/images/about_img.jpg";
import "./about.scss";
import Footer from "../../UI/footer";

const AboutPage = () => {
  const { messages } = useContext(IntlContext);
  const astrik_lang = messages.en;
  const mainWrapperRef = useRef();

  useEffect(() => {
    mainWrapperRef.current.scrollIntoView({ behavior: "smooth" });

    return () => {};
  }, []);
  return (
    <>
      <Box className="main-wrapper-about" ref={mainWrapperRef}>
        <Typography className="heading-about-text text-pointer">
          {astrik_lang?.auth?.who_are_we}
        </Typography>
        <p className="text-font text-pointer">
          {astrik_lang?.auth?.who_are_we_content}
        </p>
        <Box className="main-who-astrik-container t-center ">
          <Box className="who-astrik-container">
            <img src={star} className="t-pointer" alt="" />
            <span>
              <p className="who-astrik-title">{astrik_lang?.auth?.astrik}</p>
            </span>
          </Box>
          <p className="who-astrik-para t-center">
            {astrik_lang?.auth?.driven_by_passion_delivering_excellence}
          </p>
          <Box className="ellipse-shadow-about-container">
            <img className="abouts-img t-pointer" src={about} alt="" />
          </Box>
        </Box>
        <Typography className="heading-about-text text-pointer">
          {astrik_lang?.auth?.what_makes_us_different}
        </Typography>
        <p className="text-font">
          {astrik_lang?.auth?.what_makes_us_different_content}
        </p>
        <Box className="abouts-diffrents-main-section">
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={inclusive} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Inclusive</p>
              <p className="diffrents-para t-end">
                "We embrace diversity and welcome all with open arms, creating a
                culture of belonging."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <p className="diffrents-title t-start">Purpose-driven</p>
              <p className="diffrents-para t-start">
                "We're driven by a clear sense of purpose and a desire to make a
                positive difference in the world."
              </p>
            </Box>
            <Box className="right-diffrents-part">
              <img className="diffrents-imgs" src={purposeDriven} alt="" />
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={agile} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Agile</p>
              <p className="diffrents-para t-end">
                "We're flexible and adaptable, able to pivot quickly and stay
                ahead of the curve."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <p className="diffrents-title t-start">Customer-obsessed</p>
              <p className="diffrents-para t-start">
                "Our customers are our obsession, and we're dedicated to
                exceeding their expectations."
              </p>
            </Box>
            <Box className="right-diffrents-part">
              <img className="diffrents-imgs" src={customerObsessed} alt="" />
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={bold} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Bold</p>
              <p className="diffrents-para t-end">
                "We're fearless and unafraid to take risks, constantly pushing
                the boundaries of what's possible."
              </p>
            </Box>
          </Box>
        </Box>
        <Box className="abouts-diffrents-main-section-responsive">
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={inclusive} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Inclusive</p>
              <p className="diffrents-para t-end">
                "We embrace diversity and welcome all with open arms, creating a
                culture of belonging."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={purposeDriven} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Purpose-driven</p>
              <p className="diffrents-para t-end">
                "We're driven by a clear sense of purpose and a desire to make a
                positive difference in the world."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={agile} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Agile</p>
              <p className="diffrents-para t-end">
                "We're flexible and adaptable, able to pivot quickly and stay
                ahead of the curve."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={customerObsessed} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Customer-obsessed</p>
              <p className="diffrents-para t-end">
                "Our customers are our obsession, and we're dedicated to
                exceeding their expectations."
              </p>
            </Box>
          </Box>
          <Box className="abouts-diffrents-container t-pointer">
            <Box className="left-diffrents-part">
              <img className="diffrents-imgs" src={bold} alt="" />
            </Box>
            <Box className="right-diffrents-part">
              <p className="diffrents-title t-end">Bold</p>
              <p className="diffrents-para t-end">
                "We're fearless and unafraid to take risks, constantly pushing
                the boundaries of what's possible."
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AboutPage;
