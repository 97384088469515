import React from "react";
import { Box } from "@mui/material";
import work from "../../../assets/images/work-img.svg";
import CloudNationCover from "../../../assets/images/CloudNation Cover.png";
import ChotaDhobiCover from "../../../assets/images/ChotaDhobiCover.png";
import "./card.scss";
import { Carousel } from "3d-react-carousal";

let slides = [
  <div className="card-content ">
    <img className="card-img " src={work} alt="1" />
    <div className="card-detail-sec ">
      <p className="card-title">InstaTickets</p>
      <p className="card-para">
        Say goodbye to long queues and hello to hassle-free ticket booking for
        movies, operas, concerts, and more. With a delightful and user-friendly
        interface, InstaTickets brings joy and excitement to your fingertips.
        Get ready to dive into a world of entertainment with just a few taps.
        From blockbuster movies to show-stopping performances, InstaTickets is
        your go-to companion for all things fun and fabulous!
      </p>
    </div>
  </div>,
  <div className="card-content ">
    <img className="card-img-chotadhobi" src={CloudNationCover} alt="2" />
    <div className="card-detail-sec ">
      <p className="card-title">Cloudnation</p>
      <p className="card-para">
        Cloudnation is a Platform-as-a-Service (PaaS) cloud-based service that
        automates deployment and infrastructure management. Our focus is on
        simplicity, ensuring a stress-free experience in handling cloud
        infrastructure, allowing businesses to concentrate on growth rather than
        infrastructure management.
      </p>
    </div>
  </div>,
  <div className="card-content ">
    <img className="card-img-chotadhobi " src={ChotaDhobiCover} alt="3" />
    <div className="card-detail-sec ">
      <p className="card-title">Chota Dhobi</p>
      <p className="card-para">
        ChotaDhobi revolutionizes laundry with a user-friendly mobile app.
        Customers enjoy seamless laundry requests, real-time tracking, and
        personalized preferences. For laundry shops, the app streamlines order
        management, inventory tracking, and customer communication, optimizing
        operational workflows. A modern solution benefiting both customers and
        laundry service providers.
      </p>
    </div>
  </div>,
];

const CustomCard = () => {
  return (
    <>
      <Box className="work-card-box t-pointer">
        <Carousel slides={slides} autoplay={false} interval={2000} />
      </Box>
    </>
  );
};

export default CustomCard;
